export * from './snackbar'

export const INTERCEPT_SETTING_TYPE = {
  DATE_RANGE: 'DATE_RANGE',
  COOKIES: 'COOKIES',
}
export const COOKIE = {
  MIN_EXPIRY: 1,
  MAX_EXPIRY: 365,
  DEFAULT_COOKIE_TIME: 0,
}

export const LABEL = {
  COOKIE_EXPIRY: 'Cookies expiry',
  DATE_RANGES: 'Date Ranges',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  OR: 'OR',
  SETTINGS: 'Settings',
  EXPIRE_IN_DAYS: 'Expire In Days',
  NAME: 'Name',
  SURVEY_COUNT: 'Survey count',
  PROJECTS_COUNT: 'Projects count',
  INTERCEPTS_COUNT: 'Intercepts count',
  WHATS_NEW: "What's new?",
  CHAT_NOW: 'Chat now',
  SURVEY_TYPE: 'Survey type',
  MATCHED_VISITORS_COUNT: 'Matched visitors count',  
  LAUNCHED_VISITORS_COUNT: 'Launched visitors count',
  LAST_MODIFIED: 'Last modified',
  STATUS: 'Status',
  ACTIONS: 'Actions', 
  RULE:'Rule',  
  INTERCEPT_POSITION:'Intercept position', 
  CUSTOM_VARIABLES: "Custom Variables",
  MAPPING_TYPE: "Mapping type",
  ID_OR_VALUE: "ID/Value",
}

export const WIDGET_SETTINGS_POSITION = [
  'TOP_LEFT',
  'TOP_CENTER',
  'TOP_RIGHT',
  'BOTTOM_LEFT',
  'BOTTOM_CENTER',
  'BOTTOM_RIGHT',
]

export enum WidgetSettingsPositionEnum {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  CENTER_LEFT = 'CENTER_LEFT',
  CENTER_CENTER = 'CENTER_CENTER',
  CENTER_RIGHT = 'CENTER_RIGHT',
}

export const Color = {
  ROYAL_BLUE : "#004693",
  VIVID : "#0095dc",
  GREY : '#545E6B',
  HOVER_BACKGROUND_WHITE_SMOKE : '#F5F5F5',
}